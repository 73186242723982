html, body{
  background-image: url("./images/bg_1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: auto;
  font-family: 'Dosis-Light';
  height: 100%;
  font-size:18px;
}

.root{
  height: 100%;
  
  width: 100vw;
  max-width: 960px;
  margin: 0 auto;
  
}


.App {
  text-align: center;
  color: #414042;
  height: 100%;  
}
.header{
  background-color: #414042;     
  width: 100%;
  z-index: 10000;
}

.content{
padding-top: 80px;
padding-bottom: 80px;


}
.modul{
  
  background-color: rgba(255,255,255, 0.0);
}


.footer{  
  
    color: white;
    z-index: 10000;
}
.footer-content{

  max-width: 300px;
  background-color:  rgba(65,64,66,1);
  margin: 10px auto;
  color: whitesmoke;
 

}

.my-button{
  border-right: 1px solid whitesmoke;
}

.modal-button{
  background-color: #414042;
  color:whitesmoke;
  margin: 15px 15px 15px 0px;
  max-width: 100px;
  text-align: center;
  border-right: 1px solid whitesmoke;
  cursor: pointer;
  /* border-top-right-radius: 10px ;
  border-bottom-right-radius: 10px ; */
}

.modal-input{
  background-color: #414042;
  color:whitesmoke;
  margin: 15px 15px 15px 15px;
  max-width: 200px;
  text-align: center;
  border-right: 1px solid whitesmoke;
  cursor: pointer;
}

.modal-input:hover, .modal-button:hover{
  background-color: #212022;
}



.reg-modal{
  
}
.modal-backdrop
{
  background-color: rgba(66,64,65, 0.0);
}

.modal-content{
  margin-top: 80px;
  margin-bottom: 80px;
  border: none;
  background-color: rgba(66, 64, 65, 0.5);
  color:whitesmoke
}

.mod-content{
  margin-top: 80px;
  background-color: rgba(66, 64, 65,1);
  color:whitesmoke
}

.sub-menue{
  background-color: rgba(66,64,65,0.9);
  margin-top:15px ;
}

.my-modal{
  
}

.my-submodal{
  border-radius: 5px;
  background-color: rgba(66, 64, 65, 1.5);
}

.my-textinput{
  background-color: rgba(66, 64, 65, 1.0);
  border: none;
  border-bottom: 1px solid whitesmoke;
  color: whitesmoke;
  outline: none;
  font-size: 20px;

}


input:focus,
select:focus,
textarea:focus,
button:focus {
  border-bottom: 3px solid whitesmoke;  
}
input::placeholder{
  color: whitesmoke;
}

.blend{  
  font-size: 22px;
  transition: font-size 0.3s;
}

a{
  font-size: 20px;
  color: whitesmoke;
}
a:hover{
  color: whitesmoke;
  font-weight: bold;
}
a:link{
  color: whitesmoke;
}